import React, { useState, useRef } from 'react';
import Link from 'next/link';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { INLINES, Node } from '@contentful/rich-text-types';
import { LinkButton } from '@fashionphile/component-library';
import { useRouter } from 'next/router';
import {
  contentfulSrcSet,
  convertButtonString,
} from '../../../utilities/helpers';
import { useIsInViewOnScroll } from '../../useInViewOnScroll';
import events, { EVENTS } from '../../../utilities/segment/events';
import {
  HeroBannerData,
  TypeHeroBannerFields,
  CtaButton,
} from '../../../types/DynamicLandingPageReducer';
import classNames from 'classnames';

const HeroContent = (
  heroData: TypeHeroBannerFields,
): React.ReactElement => {
  let desktopTextColor = 'black';
  if (heroData?.textColor) {
    desktopTextColor = heroData.textColor.toLowerCase();
  }
  const textAlignment = heroData?.textAlignment?.toLowerCase() || '';

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (
        node: Node,
        children: React.ReactElement,
      ): React.ReactElement => (
        <a
          target={heroData.targetAttributeValue}
          className="hyperLink"
          href={node.data.uri}
          rel="noreferrer"
          tabIndex={0}
        >
          {children}
        </a>
      ),
    },
  };

  return (
    <div
      className={`heroContent ${textAlignment} black-mobileText ${desktopTextColor}-desktopText`}
    >
      <div className="heroContentblock">
        {heroData?.eyebrowCopy && (
          <p data-testid="hero-eyebrow" className="eyebrow">
            {heroData?.eyebrowCopy}
          </p>
        )}
        {heroData?.header && (
          <p data-testid="hero-header" className="heroHeader">
            {heroData?.header}
          </p>
        )}
        {heroData?.subcopy && (
          <h1 className="subhedAdditional">{heroData?.subcopy}</h1>
        )}
        {heroData?.subcopyTwo && (
          <p className="subhedAdditional">{heroData?.subcopyTwo}</p>
        )}
        {heroData?.subcopyThree && (
          <p className="subhedAdditional">{heroData?.subcopyThree}</p>
        )}
        {heroData?.subcopyFour && (
          <p className="subhedAdditional">{heroData?.subcopyFour}</p>
        )}
        {heroData?.ctaButtons && (
          <div className="ctaButtons">
            {heroData.ctaButtons.map((btn: CtaButton, k: number) => {
              return (
                <LinkButton
                  target={heroData.targetAttributeValue}
                  content={btn.fields?.textToDisplay}
                  size="lg"
                  variant={convertButtonString(btn.fields?.color)}
                  ariaLabel={btn.fields?.textToDisplay || ''}
                  className={`cta${k + 1}`}
                  href={btn.fields?.linkUrl || ''}
                  key={`${k}-${btn.fields?.textToDisplay}`}
                />
              );
            })}
          </div>
        )}
        {heroData?.fineprint && (
          <p className="fineprint">{heroData?.fineprint}</p>
        )}
        {heroData?.fineprintCta && (
          <div className="fineprintCTA">
            {documentToReactComponents(
              heroData?.fineprintCta,
              options as Options,
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const HeroBanner = ({
  data,
}: {
  data: HeroBannerData;
}): React.ReactElement => {
  const router = useRouter();
  const [isFocus, setIsFocus] = useState(false);
  const targetAttributeValue = data.fields?.linkOutToAnotherTab
    ? '_blank'
    : '_self';
  const title = data.fields?.title || '';
  const heroImageLink =
    data.fields?.heroImageLink ||
    data.fields?.ctaButtons?.[0].fields?.linkUrl ||
    router.asPath;
  const desktopImageUrl =
    data.fields?.desktopImage?.fields?.file?.url || '';
  const mobileImageUrl =
    data.fields?.mobileImage?.fields?.file?.url || '';
  const imageAltCopy = data.fields?.imageAltCopy || '';
  const fields = data.fields || '';
  fields.targetAttributeValue = targetAttributeValue;

  const ref = useRef<HTMLDivElement>(null);

  let mobileImagesrcSet;
  let desktopImagesrcSet;
  mobileImagesrcSet = contentfulSrcSet(mobileImageUrl, {
    sm: 577,
    md: 769,
    lg: 993,
    xl: 1281,
    xxl: 1600,
  });
  desktopImagesrcSet = contentfulSrcSet(desktopImageUrl, {
    sm: 577,
    md: 769,
    lg: 993,
    xl: 1281,
    xxl: 1600,
  });

  useIsInViewOnScroll(ref.current, {
    init: true,
    callBack: (state: boolean) => {
      if (state) {
        events.emit(EVENTS.promoViewed, {
          creative: null,
          name: title,
          position: 'top of homepage',
          promotion_id: 0,
        });
      }
    },
  });

  const handleFocusVisible = (
    event: React.KeyboardEvent<HTMLAnchorElement>,
  ): void => {
    if (event.key === 'Tab') {
      setIsFocus(true);
    }
  };

  return (
    <div
      className={classNames(
        'heroBannerContainer',
        isFocus && 'focus',
      )}
      ref={ref}
    >
      <div className="heroWrapper">
        <Link
          className="heroBannerLink"
          href={heroImageLink}
          onBlur={(): void => {
            setIsFocus(false);
          }}
          onKeyUp={handleFocusVisible}
          target={targetAttributeValue}
          title={heroImageLink}
        >
          {desktopImageUrl && mobileImageUrl && (
            <>
              <img
                srcSet={desktopImagesrcSet}
                sizes="100vw"
                alt={imageAltCopy as string}
                data-testid="hero-image"
                loading="eager"
                src={desktopImageUrl}
                className="desktopOnly"
              />
              <img
                srcSet={mobileImagesrcSet}
                sizes="100vw"
                alt={imageAltCopy as string}
                data-testid="hero-image"
                loading="eager"
                src={mobileImageUrl}
                className="mobileOnly"
              />
            </>
          )}
        </Link>
        {fields && <HeroContent {...fields} />}
      </div>
    </div>
  );
};

export default HeroBanner;
